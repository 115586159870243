import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';

import { useEffect } from 'react';

const useDatadog = () => {
  const setAdminUser = (merchantId: number, merchantName: string) => {
    datadogRum.setUser({
      merchantId: merchantId || null,
      merchantName: merchantName || null,
    });
  };

  const setUser = (
    merchantId: number,
    merchantName: string,
    userId: number,
    userPhoneNumber: string,
  ) => {
    datadogRum.setUser({
      merchantId: merchantId || null,
      merchantName: merchantName || null,
      userId: userId || null,
      userPhoneNumber: userPhoneNumber || null,
    });
  };

  const checkIfProduction = () => process.env.NODE_ENV === 'production';
  // const checkIfProduction = () => true;
  const initRum = () => {
    if (!checkIfProduction()) return;
    datadogRum.init({
      applicationId: process.env.REACT_APP_DATADOG_RUM_APPLICATION_ID as string,
      clientToken: process.env.REACT_APP_DATADOG_RUM_CLIENT_TOKEN as string,
      // `site` refers to the Datadog site parameter of your organization
      // see https://docs.datadoghq.com/getting_started/site/
      site: process.env.REACT_APP_DATADOG_RUM_SITE,
      service: process.env.REACT_APP_DATADOG_RUM_SERVICE,
      env: process.env.REACT_APP_DATADOG_RUM_ENV,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 20,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      allowedTracingUrls: [
        process.env.REACT_APP_API_URL as string,
        process.env.REACT_APP_FRANCHISE_URL as string,
        process.env.REACT_APP_API_IMG_URL as string,
      ],
      defaultPrivacyLevel: 'mask-user-input',
    });
  };
  const initLogs = () => {
    if (!checkIfProduction()) return;
    datadogLogs.init({
      clientToken: process.env.REACT_APP_DATADOG_RUM_CLIENT_TOKEN as string,
      site: process.env.REACT_APP_DATADOG_RUM_SITE,
      forwardErrorsToLogs: false,
      sessionSampleRate: 100,
      service: process.env.REACT_APP_DATADOG_RUM_SERVICE,
    });
  };

  useEffect(() => {
    initRum();
    initLogs();
  }, []);

  return { setAdminUser, setUser };
};

export default useDatadog;
